'use client';

import { AppLoadingIndicator } from '@/components/app-loading-indicator/app-loading-indicator';
import {
  appService,
  sendAppEvent,
  useAppClientDataCtxSelector,
  useAppCtxSelector,
  useAppStateMatches,
  useAppStateSummary,
  useSelector,
} from '@/core';
import { isSiteWithCustomLandingPage } from '@/core/pages/custom-landing-page';
import { useSiteLoading } from '@/core/site/utils/use-site-loading';
import { loginWithRedirect } from '@/services/auth0';
import { Banner, TextBody } from '@knapsack/toby';
import { TextLink } from '@/components/text-link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

const ErrorPage = dynamic(() => import('@/core/pages/error-page'));
const CustomLandingPage = dynamic(
  () => import('@/core/pages/custom-landing-page/custom-landing-page'),
  {
    // accesses `window` in module scope
    ssr: false,
  },
);
export default function SiteInstanceLayout({
  children,
  params: { siteId: siteIdUrlParam, instanceId: instanceIdUrlParam },
}: {
  children: React.ReactNode;
  params: { siteId: string; instanceId: string };
}) {
  useSiteLoading({
    siteIdUrlParam,
    instanceIdUrlParam,
  });

  const { siteTopState } = useAppStateSummary();
  const status = useAppCtxSelector(({ site }) => site?.meta.status);
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  const title = useSelector((s) => s.db.settings.title);
  const isNotAccess = useAppStateMatches('site.loaded.appClient.noAccess');
  const isNeedsAuth = useAppStateMatches('site.loaded.appClient.needsAuth');
  const isViewable = useAppStateMatches('site.loaded.appClient.viewable');
  const isUnknown = useAppStateMatches('site.loaded.appClient.unknown');
  const loaderError = useAppClientDataCtxSelector((ctx) => ctx.loaderError);

  const router = useRouter();
  if (loaderError) {
    return <Banner type="error" title={loaderError} />;
  }
  switch (siteTopState) {
    case 'loaded': {
      switch (status) {
        case 'PREPARING': {
          return <h1>Preparing...</h1>;
        }
        case 'DISABLED': {
          return (
            <ErrorPage
              graphic="update-required"
              title="Your workspace is currently disabled for planned maintenance."
              message={
                <TextBody size="large">
                  Please contact support at{' '}
                  <TextLink href="mailto:help@knapsack.cloud" external>
                    help@knapsack.cloud
                  </TextLink>{' '}
                  for more information.
                </TextBody>
              }
            />
          );
        }
        // @todo figure out difference between these two, likely consolidate
        case 'INACTIVE':
        case 'ARCHIVED': {
          return (
            <ErrorPage
              graphic="site-id-not-found"
              title={
                status === 'ARCHIVED'
                  ? 'This workspace has been archived'
                  : 'This workspace is inactive'
              }
              message={
                <TextBody size="large">
                  Please contact support at{' '}
                  <TextLink href="mailto:help@knapsack.cloud" external>
                    help@knapsack.cloud
                  </TextLink>{' '}
                  for more information.
                </TextBody>
              }
            />
          );
        }
        case 'ACTIVE': {
          // Either public or signed-in and authorized
          if (isViewable) {
            return children;
          }

          // Still deciding what state we're in
          if (isUnknown) {
            return <AppLoadingIndicator />;
          }

          // Private and not authorized
          if (isNotAccess) {
            return (
              <ErrorPage
                graphic="no-access"
                title="You don't have access to this workspace"
                actions={[
                  {
                    label: 'Switch workspace',
                    onClick: () => router.push('/'),
                  },
                  {
                    label: 'Log out',
                    onClick: () => sendAppEvent('user.signOut'),
                  },
                ]}
              />
            );
          }

          // Custom landing/sign in pages per-customer
          if (isNeedsAuth && isSiteWithCustomLandingPage(siteId)) {
            return <CustomLandingPage siteId={siteId} title={title} />;
          }

          // Everything else
          return (
            <ErrorPage
              graphic="private-workspace"
              title="Looks like you stumbled across a private design system"
              actions={[
                {
                  label: 'Sign In',
                  onClick: () => loginWithRedirect(),
                },
              ]}
            />
          );
        }
        default: {
          const _exhaustiveCheck: never = status;
          return (
            <ErrorPage
              title={`Workspace has an unknown status in our DB: ${status}`}
            />
          );
        }
      }
    }
    case 'failure': {
      const { siteError } = appService.state.context;
      switch (siteError.info?.type) {
        case 'outdated-app-client': {
          const { requiredVersion, currentVersion } = siteError.info;
          return (
            <ErrorPage
              graphic="update-required"
              title={siteError.title}
              message={
                <TextBody size="large">
                  Required version: {requiredVersion}
                  <br />
                  Current version: {currentVersion}
                  <br />
                  Run `npx @knapsack/update@latest` to update.
                </TextBody>
              }
              actions={[
                {
                  label: 'Docs on updating',
                  onClick:
                    'https://docs.knapsack.cloud/site/ks-docs/latest/pages/updating-knapsack',
                },
              ]}
            />
          );
        }
        case 'siteId-not-found': {
          return (
            <ErrorPage graphic="site-id-not-found" title={siteError.title} />
          );
        }
        case 'unknown': {
          return <ErrorPage graphic="unknown" title={siteError.title} />;
        }
        case 'app-client-connection-failed': {
          return <ErrorPage graphic="no-connection" title={siteError.title} />;
        }
        case 'app-client-deployments-not-set-up': {
          return (
            <ErrorPage
              graphic="update-required"
              title={siteError.title}
              actions={[
                {
                  label: 'Docs on how to run locally',
                  onClick:
                    'https://docs.knapsack.cloud/site/ks-docs/latest/pages/running-knapsack-locally',
                },
              ]}
            />
          );
        }
        default: {
          const _exhaustiveCheck: never = siteError.info;
          return <ErrorPage title={siteError.title} />;
        }
      }
    }
    case 'unloaded':
    case 'loading': {
      return <AppLoadingIndicator />;
    }
    default: {
      const _exhaustiveCheck: never = siteTopState;
      return (
        <ErrorPage
          title={`Workspace experienced an unknown loading state: ${siteTopState}`}
        />
      );
    }
  }
}
