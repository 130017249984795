import { appService } from '@/core';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { createToast } from '@knapsack/toby';
import { gqlAppLoadClient } from '@/services/app-loading';

async function matchCustomToSpecificSiteInstance(customUrl: string): Promise<
  | {
      hasMatch: true;
      siteId: string;
      instanceId: string;
      isLatestInstance: boolean;
    }
  | { hasMatch: false }
> {
  // We want to search for deploys that match either `x.com` OR `x.com/` - First: remove any trailing `/`
  const url = customUrl.endsWith('/') ? customUrl.slice(0, -1) : customUrl;

  const data = await gqlAppLoadClient.GetMatchingDeploys({
    urls: [url, `${url}/`],
  });
  // What came in as `/site/custom/https://123.com` is now matched to siteInstance so should be redirected to `/site/siteId/instanceid`
  const [deploy] = data?.siteInstances ?? [];
  if (!deploy) {
    return {
      hasMatch: false,
    };
  }
  const {
    site: { id: siteId, latestUrl },
    instanceId,
  } = deploy;
  if (!siteId || !instanceId) {
    return {
      hasMatch: false,
    };
  }
  return {
    hasMatch: true,
    siteId,
    instanceId,
    isLatestInstance: latestUrl === url || latestUrl === `${url}/`,
  };
}

export function useSiteLoading({
  siteIdUrlParam,
  instanceIdUrlParam,
}: {
  siteIdUrlParam: string;
  instanceIdUrlParam: string;
}): void {
  const router = useRouter();
  useEffect(() => {
    if (!siteIdUrlParam || !instanceIdUrlParam) {
      return;
    }

    const isCustomSiteInstance = siteIdUrlParam === 'custom';
    if (!isCustomSiteInstance) {
      appService.send({
        type: 'visitSite',
        info: {
          type: 'site',
          siteId: siteIdUrlParam,
          instanceId: instanceIdUrlParam,
        },
      });
      return;
    }

    /**
     * On Vercel deploys, the instanceIdUrlParam is encoded twice.
     * This is because the instanceIdUrlParam is a string that contains a URL, and the URL is encoded twice.
     * The first encoding is done by the Next.js router, and the second encoding is done by the browser.
     * We need to decode the instanceIdUrlParam twice to get the original URL.
     * Decoding twice on a param that is encoded once is ok
     */
    const customUrl = decodeURIComponent(
      decodeURIComponent(instanceIdUrlParam),
    );
    try {
      const u = new URL(customUrl);
    } catch (e) {
      createToast({
        type: 'error',
        title: 'URL is incorrectly formed',
        message: `Retrieved "${customUrl}" from "/site/${siteIdUrlParam}/${instanceIdUrlParam}".`,
      });
      throw new Error(`URL is incorrectly formed: ${customUrl}`);
    }

    const isLocal =
      customUrl.includes('localhost') || customUrl.includes('127.0.0.1');
    if (isLocal) {
      appService.send({
        type: 'visitSite',
        info: {
          type: 'custom',
          customUrl,
        },
      });
      return;
    }

    matchCustomToSpecificSiteInstance(customUrl).then((results) => {
      if (results.hasMatch) {
        const { siteId: siteId2, instanceId, isLatestInstance } = results;
        const url = `/site/${siteId2}/${
          isLatestInstance ? 'latest' : instanceId
        }`;
        console.log(
          `This custom instance appears to for siteId "${siteId2}", instanceId "${instanceId}". Redirecting from "${window.location.pathname}" => "${url}"`,
        );
        console.groupEnd();
        router.push(url);
      } else {
        console.log(`custom url does not match known instance. loading...`, {
          siteIdUrlParam,
          instanceIdUrlParam,
          customUrl,
        });
        console.groupEnd();
        appService.send({
          type: 'visitSite',
          info: {
            type: 'custom',
            customUrl,
          },
        });
      }
    });
  }, [siteIdUrlParam, instanceIdUrlParam, router]);
}
