import { assignInlineVars } from '@vanilla-extract/dynamic';
import {
  loginWithRedirectEJ,
  loginWithRedirectGsk,
  loginWithRedirectTHD,
  loginWithRedirectGskDigitalChannels,
  loginWithRedirectOpisnet,
} from '@/services/auth0';
import { getCustomDomainId } from '@/utils/custom-domain-utils';
import { customLandingPageTheme } from './custom-landing-page.css';
import {
  SiteWithCustomLandingPage,
  customLandingPageSites,
} from './custom-landing-page.types';

export function isSiteWithCustomLandingPage(
  siteId: string,
): siteId is SiteWithCustomLandingPage {
  return (
    customLandingPageSites.includes(siteId as SiteWithCustomLandingPage) ||
    customLandingPageSites.includes(
      getCustomDomainId(window.location.hostname) as SiteWithCustomLandingPage,
    )
  );
}

export function getFooterContent(siteId: SiteWithCustomLandingPage) {
  switch (siteId) {
    case 'axiom':
      return (
        <p>
          Are you a vendor or agency partner?
          <br />
          Email{' '}
          <a href="mailto:axiom-design-system@edwardjones.com?subject=Requesting%20access%20to%20Brand%20Reference%20Site">
            axiom-design-system@edwardjones.com
          </a>{' '}
          to request access
        </p>
      );
    case 'gsk-digital-channels':
      return null;
    case 'gsk-us-usxf3':
    case 'gsk-us-staging':
    case 'gsk-us':
      return (
        <p>
          Need Access? Submit your request to the GSK Digital Experience Manager
          (DXM) aligned to your project
        </p>
      );
    case 'knapsack-nucleus':
      return (
        <p>
          Are you a vendor or agency partner?
          <br />
          Email{' '}
          <a href="mailto:brand_support@homedepot.com?subject=Requesting%20access%20to%20Brand%20Reference%20Site">
            brand_support@homedepot.com
          </a>{' '}
          to request access
        </p>
      );
    case 'opisnet':
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ maxWidth: '390px' }}>
            <p>
              If you are not already set up for Single Sign-On (SSO), please:
            </p>
            <ol style={{ paddingBottom: '1rem', textAlign: 'left' }}>
              <li>Go to your Okta account.</li>
              <li>Click on Sofi.</li>
              <li>Fill out the request.</li>
            </ol>
            <p>
              If you encounter any problems, please reach out to{' '}
              <a href="mailto:uxteam@opisnet.com?subject=Requesting%20access%20to%20design%20system">
                uxteam@opisnet.com
              </a>
              .
            </p>
            <p>Otherwise:</p>
          </div>
        </div>
      );
    default: {
      const _exhaustiveCheck: never = siteId;
      throw new Error(`Unhandled siteId: ${_exhaustiveCheck}`);
    }
  }
}

export function getSignInAction(siteId: SiteWithCustomLandingPage) {
  switch (siteId) {
    case 'axiom':
      return loginWithRedirectEJ();
    case 'gsk-us-usxf3':
    case 'gsk-us-staging':
    case 'gsk-us':
      return loginWithRedirectGsk();
    case 'gsk-digital-channels':
      return loginWithRedirectGskDigitalChannels();
    case 'knapsack-nucleus':
      return loginWithRedirectTHD();
    case 'opisnet':
      return loginWithRedirectOpisnet();
    default: {
      const _exhaustiveCheck: never = siteId;
      throw new Error(`Unhandled siteId: ${_exhaustiveCheck}`);
    }
  }
}

export function getThemeStyles(siteId: SiteWithCustomLandingPage) {
  switch (siteId) {
    case 'axiom':
      return assignInlineVars(customLandingPageTheme, {
        buttonPrimary: {
          backgroundColor: '#0076A0',
          backgroundColorFocus: '#044D66',
          backgroundColorHover: '#044D66',
        },
        buttonSecondary: {
          borderColor: '#3b3d3f',
          borderColorHover: '#0076A0',
        },
        logo: {
          width: '260px',
        },
      });
    case 'gsk-digital-channels':
    case 'gsk-us-usxf3':
    case 'gsk-us-staging':
    case 'gsk-us':
      return assignInlineVars(customLandingPageTheme, {
        buttonPrimary: {
          backgroundColor: '#F36633',
          backgroundColorFocus: '#F36633',
          backgroundColorHover: '#F36633',
        },
        logo: {
          height: '64px',
        },
        title: {
          color: '#3b3d3f',
        },
      });
    case 'knapsack-nucleus':
      return assignInlineVars(customLandingPageTheme, {
        buttonPrimary: {
          backgroundColor: 'rgb(249,99,2)',
          backgroundColorHover: 'rgb(33, 33, 32)',
          backgroundColorFocus: 'rgb(251, 197, 36)',
          textColorFocus: 'rgb(33, 33, 32)',
        },
        buttonSecondary: {
          borderColor: 'rgb(33, 33, 32)',
          borderColorHover: 'rgb(249,99,2)',
          textColorHover: 'rgb(33, 33, 32)',
        },
        logo: {
          width: '160px',
        },
      });
    case 'opisnet':
      return assignInlineVars(customLandingPageTheme, {
        buttonPrimary: {
          backgroundColor: 'rgb(2,163,213)',
          backgroundColorHover: 'rgb(27, 110, 191)',
          backgroundColorFocus: 'rgb(27, 110, 191)',
          textColorFocus: 'rgb(255, 255, 255)',
        },
        buttonSecondary: {
          borderColor: 'rgb(2,163,213)',
          borderColorHover: 'rgb(27, 110, 191)',
          textColorHover: 'rgb(2,163,213)',
        },
        logo: {
          width: '260px',
        },
      });
    default: {
      const _exhaustiveCheck: never = siteId;
      throw new Error(`Unhandled siteId: ${_exhaustiveCheck}`);
    }
  }
}
